.gpa_video {
    .gpa_video_grid {
        margin-bottom: 64px;

        .gpa_video_item_anchor {
            text-decoration: none;
            .gpa_video_preview {
                position: relative;

                img {
                    width: 100%;
                    height: 150px !important;
                    aspect-ratio: 3 / 2;
                    object-fit: cover;
                    object-position: center;
                }
                .gpa_video_layer_black {
                    background-color: rgba(0, 0, 0, .3);
                    width: 100%;
                    height: 100%;
                }
                .gpa_video_preview_cover {
                    position: absolute !important;
                    background-color: var(--blue-opacity-5) !important;
                    height: 100%;
                    opacity: 0;
                    transition: .2s;
                    text-align: center;
                    align-items: center;
                    align-content: center;

                    .gpa_video_icon_play {
                        * {
                            fill: var(--white) !important;
                        }
                    }
                }
                .gpa_video_preview_cover:hover {
                    opacity: 1;
                    transition: opacity .3s;
                }
            }
            .gpa_video_item_title {
                line-height: initial;
                color: var(--white);
                padding: 5px;
                span {
                    display: block;
                    color: var(--white);
                }
                span:last-child,
                span:nth-last-child(2) {
                    font-size: 16px;
                    font-weight: 600;
                }
                span:first-child {
                    font-weight: 400 !important;
                }
            }
        }
        .gpa_video_item_copy_url {
            font-size: 12px;
            line-height: 22px;
            margin-top: 8px;
            * {
                color: var(--blue) !important;
                fill: var(--blue) !important;
            }
            span {
                cursor: pointer;
                margin-right: 16px;
                svg {
                    margin-right: 4px;
                }
            }
            span:active {
                cursor: grabbing;
            }
            .gpa_video_item_icon_copied svg * {
                color: var(--blue) !important;
            }
        }
    }
}
#videos-gpa {
    .gpa_video_layer_black {
        display: none;
    }
}