.gpa_switcher {
    .gpa_switcher_nav {
        border-bottom: 2px solid var(--black-op-5);
        li {
            height: 40px;
            padding: 0;
            transform: translate(0,2px);

            &.uk-active,
            &:hover {
                border-bottom: 2px solid var(--gray1);
                * {
                    font-weight: 600;
                    color: var(--black);
                }
            }

            button {
                border: 0;
                height: 100%;
                padding: 0px 30px;
                font-size: 18px;
                color: var(--black-op-5);
                text-transform: none;
                cursor: pointer;
            }
        }
    }
    .gpa_switcher_cont_item_cover {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        transition: opacity .3s;

        * {
            color: var(--white);
            font-weight: 600;
        }
        &:hover {
            opacity: 1;
            background-color: var(--blue-opacity-5);
        }
    }
}