$black: #24272A;
$black_rgb: 36 39 42;
$white: #FFFFFF;
$white_rgb: 250 250 250;

$avenir: 'AvenirNextLTPro';
$avenirCN: 'AvenirNextLTProCN';
$font: '../fonts/AvenirNextLTPro-';
/* Fonts */
@font-face {
    font-family: $avenir;
    font-style: normal;
    font-weight: 300;
    src: url('#{$font}UltLt.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: $avenir;
    font-style: normal;
    font-weight: 400;
    src: url('#{$font}Regular.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: $avenir;
    font-style: italic;
    font-weight: 400;
    src: url('#{$font}It.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: $avenir;
    font-style: normal;
    font-weight: 600;
    src: url('#{$font}Demi.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: $avenir;
    font-style: italic;
    font-weight: 600;
    src: url('#{$font}DemiIt.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: $avenir;
    font-style: normal;
    font-weight: 700;
    src: url('#{$font}Bold.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: $avenir;
    font-style: italic;
    font-weight: 700;
    src: url('#{$font}BoldIt.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: $avenir;
    font-style: normal;
    font-weight: 900;
    src: url('#{$font}Heavy.otf') format('opentype');
    font-display: swap;
}
/* Condensed Ultralight */
@font-face {
    font-family: $avenirCN;
    font-style: normal;
    font-weight: 300;
    src: url('#{$font}UltLtCn.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: $avenirCN;
    font-style: italic;
    font-weight: 300;
    src: url('#{$font}UltLtCnIt.otf') format('opentype');
    font-display: swap;
}
/* Condensed Regular */
@font-face {
    font-family: $avenirCN;
    font-style: normal;
    font-weight: 400;
    src: url('#{$font}Cn.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: $avenirCN;
    font-style: italic;
    font-weight: 400;
    src: url('#{$font}CnIt.otf') format('opentype');
    font-display: swap;
}
/* Condensed Medium */
@font-face {
    font-family: $avenirCN;
    font-style: normal;
    font-weight: 600;
    src: url('#{$font}MediumCn.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: $avenirCN;
    font-style: italic;
    font-weight: 600;
    src: url('#{$font}MediumCnIt.otf') format('opentype');
    font-display: swap;
}
/* Condensed Demi */
@font-face {
    font-family: $avenirCN;
    font-style: normal;
    font-weight: 700;
    src: url('#{$font}DemiCn.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: $avenirCN;
    font-style: italic;
    font-weight: 700;
    src: url('#{$font}DemiCnIt.otf') format('opentype');
    font-display: swap;
}
/* Condensed Bold */
@font-face {
    font-family: $avenirCN;
    font-style: normal;
    font-weight: 800;
    src: url('#{$font}BoldCn.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: $avenirCN;
    font-style: italic;
    font-weight: 800;
    src: url('#{$font}BoldCnIt.otf') format('opentype');
    font-display: swap;
}
/* Condensed Heavy */
@font-face {
    font-family: $avenirCN;
    font-style: normal;
    font-weight: 900;
    src: url('#{$font}HeavyCn.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: $avenirCN;
    font-style: italic;
    font-weight: 900;
    src: url('#{$font}HeavyCnIt.otf') format('opentype');
    font-display: swap;
}

:root {
    --avenir: #{$avenir};
    --avenirCN: #{$avenirCN};
    --black: #{$black};
    --black2: #333;
    --white: #{$white};
    --white2: #f2f2f2;
    --white3: #f5f5f5;
    --white4: #f6f6f6;
    --white5: #f8f8f8;
    --gray1: #444444;
    --gray2: #8C8F90;
    --gray3: #D9D9D9;
    --gray4: #EDEDED;
    --gray5: #E5E5E5;
    --blue: #64b6cf;
    --blue1: #0094FF;
    --blue-opacity-3: rgb(100 182 207 / 30%);
    --blue-opacity-5: rgb(100 182 207 / 50%);
    --blue3: #DBEAF0;
    --blue4: #63A2BC;
    --blue4-opacity-2: rgb(99 162 188 / 20%);
    --black-op-5: rgb(#{$black_rgb} / 50%);
    --black-op-7: rgb(#{$black_rgb} / 70%);
    --black-op-9: rgb(#{$black_rgb} / 97%);
    --red: #fb4a3f;
    --fsize-01: 10px;
    --fsize-02: 12px;
    --fsize-03: 14px;
    --fsize-04: 16px;
    --fsize-05: 18px;
    --fsize-06: 20px;
    --fsize-07: 24px;
    --fsize-08: 28px;
    --fsize-09: 30px;
    --fsize-10: 32px;
    --fsize-11: 35px;
    --fsize-12: 40px;
    --fsize-13: 56px;
    /* Space - Sizes */
    --space-0: 0;
    --space-1: 10px;
    --space-2: 15px;
    --space-3: 20px;
    --space-4: 30px;
    --space-5: 40px;
    --space-6: 64px;
    --space-7: 84px;
    --space-8: 100px;
    --space-9: 110px;
    --space-10: 128px;
    --space-11: 140px;
    --space-12: 220px;
    --color-op: 100%;
}
.dark {
    --black: #{$white};
    --white: #{$black};
    --black-op-7: rgb(#{$white_rgb} / 70%);
    --black-op-9: rgb(#{$white_rgb} / 97%);
}
* {
  box-sizing: border-box;
}
body {
  background-color: var(--white);
}
body :not(i) {
  font-family: var(--avenir);
}
h1{
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
}
h2{
  font-size: 24px;
  line-height: 34px;
  font-weight: 600;
}
h3{
  font-size: 28px;
  line-height: 38px;
  font-weight: 600;
}
h4{
  font-size: 18px;
  line-height: 28px;
}
p {
  font-size: 14px;
}

.gpa-margin-medium-bottom {
  margin-bottom: 46px;
}
.gpa_grid {
  width: 100%;
  display: grid;
  column-gap: 25px;
  row-gap: 20px;
}
@for $i from 1 through 12{
  .gpa_col_#{$i} {
      grid-template-columns: repeat(#{$i}, 1fr);
  }
}
.gpa_height_250 {
  object-fit: cover;
  object-position: center;
  height: 260px;
}