.gpa_sc_anchor {
    text-decoration: none !important;
    .gpa_sc_anchor_banner {
        p {
            font-weight: 600;
        }
    }
    .gpa_sc_anchor_cover {
        background-color: var(--blue-opacity-5);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        opacity: 0;
        transition: opacity .3s;
        * {
            color: var(--white);
        }
        &:hover {
            opacity: 1;
        }
        span {
            font-weight: 600;
        }
    }
}
.gpa_sc_modal {
    .gpa_modal_body {
        padding: 20px;
        .gpa_sc_preview_cover {
            * {
                color: var(--black);
                font-weight: 600;
            }
        }
        .gpa_sc_download_buttons {
            .gpa_download_pdf {
                text-decoration: none;
                background-color: var(--blue);
                height: 40px;
                padding: 0 20px;
                border-radius: 3px;
                border: 1px solid var(--blue);
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                transition: all .3s;
    
                .gpa_download_pdf_text {
                    color: var(--white);
                    font-weight: 600;
                    font-size: 14px;
                    transition: all .3s;
                }
                &:hover {
                    background-color: var(--white);
                    .gpa_download_pdf_text {
                        color: var(--blue);
                    }
                }
            }
        }
    }
}