.uk-button {
    padding: 0;
}
.uk-lightbox-items {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}