.gpa_download_pdf {
    cursor: pointer;

    .gpa_download_pdf_image {
        .gpa_download_pdf_title {
            font-weight: 600;
        }
    }
    .gpa_download_pdf_preview_mask {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all .3s;
        opacity: 0;

        * {
            color: var(--white);
            font-weight: 600;
        }
    }
    &:hover {
        .gpa_download_pdf_preview_mask {
            background-color: var(--blue-opacity-5);
            opacity: 1;
        }
    }

    &:active {
        cursor: grabbing;
    }
}