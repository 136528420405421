.gpa_nav {
    background: #ededed !important;
    box-shadow: none;
    width: 230px;
    height: 100%;
    left: 0;
    top: 0;
    position: fixed;
    z-index: 35;
    padding: 40px 25px;
    box-sizing: border-box;
    .gpa_nav_logo {
        margin-bottom: 40px;
    }
    .gpa_nav_menu {
        ul {
            margin-left: 20px;
        }
        a {
            font-size: 14px;
            line-height: 24px;
            padding: 5px 0px;
            color: var(--black);
            transition: color .5s;
        }
        a:hover {
            color: var(--blue);
        }
    }
}